import React from "react";

export default function PlayAndEarn() {
  return (
    <>
      {/* <h1 className="short-hr-center text-center my-5 py-5">Play & Earn</h1> */}
      <div className="row extra-features right">
        <div className="col-xl-6 col-md-12">
          <h2 className="short-hr-right">Play & Earn</h2>
          <p className="text">
            Use your martians and their equipment mods to compete and earn across multiple sports game titles and mini-games.
          </p>
        </div>
        <div className="col-md-6 text-center playy pt-4">
          <video
            src="./images/ice-martians.mp4"
            autoPlay
            loop
            muted
            playsInline
            className="img-fluid animation-element pt-md-5 mt-md-4 slide-in-right"
            width="700"
          ></video>
        </div>
      </div>
    </>
  );
}
