import React from "react";

export default function HeroSection() {
  return (
    <section id="hero-section">
      <div className="row hero-unit">
        <div className="hero-overlay"></div>
        <video autoPlay loop muted playsInline id="bgvid" className="img-fluid">
          <source src="./images/bg-vid.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="hero-caption">
            <img
              src="images/headline.png"
              className="img-fluid headline"
              alt="source code"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
