import React from 'react'
import CollectAndCustomize from './CollectAndCustomize'
import Exchange from './Exchange'
import Minigames from './Minigames'
import PlayAndEarn from './PlayAndEarn'

export default function MajorGameTitle() {
  return (
    <section id="features-section">
      <div className="container-fluid mt-5 pt-5">
        <h1 className="short-hr-center text-center mb-5 pb-5">Major Game Titles</h1>
        <div className="row features text-center">
          <div className="col-md-4 feature-card">
            <h2 className="short-hr-center">
              <img
                src="./images/Ice_Martians.png"
                alt="Ice_Martians"
                width="350"
              />
            </h2>
          </div>
          <div className="col-md-4 feature-card">
            <h2 className="short-hr-center">
              <img
                src="./images/Field_Martians.png"
                alt="Field_Martians"
                width="350"
              />
            </h2>
          </div>
          <div className="col-md-4 feature-card">
            <h2 className="short-hr-center">
              <img
                src="./images/Hoop_Martians.png"
                alt="Hoop_Martians"
                width="350"
              />
            </h2>
          </div>
        </div>
        <Minigames/>
        <div className="my-5 py-5">
            <CollectAndCustomize/>
            <PlayAndEarn/>
            <Exchange/>
        </div>
      </div>
    </section>
  )
}
