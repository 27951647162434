import React from "react";

export default function CollectAndCustomize() {
  return (
    <>
      {/* <h1 className="short-hr-center text-center mb-5 pb-5">Collect & Customize</h1> */}
      <div className="row extra-features">
        <div className="col-md-6 text-center pt-md-4 order-last order-md-first">
          <img
            src="./images/collect.png"
            className="img-fluid animation-element slide-in-left m-0 p-0 mt-md-5 pt-md-5"
            width="700"
            alt="collectandcustomize"
          />
        </div>
        <div className="col-xl-6 col-md-12 order-first order-md-last">
          <h2 className="short-hr-left">Collect & Customize</h2>
          <p className="text">
            Collect & store your digital assets in your lockeroom and use your
            inventory to equip, Customize & upgrade your martians.
          </p>
        </div>
      </div>
    </>
  );
}
