import React, { useEffect, useState } from "react";
import $ from "jquery";

export default function Loader() {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    //loader
    window.onload = function () {
      $("#progress").animate({ width: "100%" }, 10, function () {
        setLoaded(true);
      });
    };

    if (document.readyState === 'complete'){
      setLoaded(true);
    }
    
  }, []);

  return (
    <div id="loader-wrapper" className={(loaded? "loaded":"")}>
      <h1 className="loader-logo colored">
        <img src="./images/logo.png" alt="logo" width="300" height="80" />
      </h1>
      <div id="progress" style={{width: loaded ? '0%' : '100%'}}></div>
      <h3 className="loader-text text-white">LOADING</h3>
      <div className="lds-roller loader-text">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
