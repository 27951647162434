import React from "react";

export default function AboutSection() {
  return (
    <section id="about-section" className="py-5 my-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-md-5 pt-md-5">
            <h1 className="short-hr-left">Martians Universe</h1>
            <p className="text">
              The first virtual sports gaming platform where players can use
              their verifiably owned assets to play, compete and earn across
              multiple sports game titles and mini-games
            </p>
          </div>
          <div className="col-md-6 pt-2">
            <div className="d-flex justify-content-center">
              <img src="./images/display1.png" alt="display 1" width="300" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
