import React from "react";

export default function Navbar() {
  return (
    <header id="main-header">
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
        <div className="container">
          <img
            src="./images/logo.png"
            id="main-logo"
            className="navbar-brand"
            width="180"
            alt="logo"
          />
          <button id="hamburger" className="navbar-toggler" disabled>
            <div className="d-flex flex-row">
              <a
                className="px-1"
                href="https://discord.com/invite/TBKTP8cmUW"
              >
                <i className="fab fa-discord"></i>
              </a>
              <a className="px-1" href="https://twitter.com/Martians_Verse">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto text-center d-sm-flex justify-content-center flex-row">
              <li className="nav-item">
                <a className="nav-link" href="https://discord.com/invite/TBKTP8cmUW">
                  <i className="fab fa-2x fa-discord"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://twitter.com/Martians_Verse">
                  <i className="fab fa-2x fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
