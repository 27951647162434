import React, { useEffect } from 'react'
import LightBox from "lightbox2"

export default function Minigames() {

    useEffect(() => {
        LightBox.option({
            'resizeDuration': 400,
            'imageFadeDuration': 400,
            'wrapAround': true
        });
      });

  return (
    <section id="media-section">
        <h1 className="short-hr-center text-center my-5 py-5">Mini Game Titles</h1>
      <div className="grid-gallery">
        <div className="row">
        <div className="col-md-4 gallery-item">
            <a href="./images/Kickoff.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="./images/Kickoff.png" className="img-fluid b-lazy b-loaded" alt="Kickoff" />
              <h1 className='text-white text-center'>Kickoff</h1>
            </a>
          </div>
          <div className="col-md-4 gallery-item">
            <a href="./images/Infinite_Skater.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="./images/Infinite_Skater.png" className="img-fluid b-lazy b-loaded" alt="Infinite Skater" />
              <h1 className='text-white text-center'>Infinite Skater</h1>
            </a>
          </div>
          <div className="col-md-4 gallery-item">
            <a href="./images/Baseball.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="./images/Baseball.png" className="img-fluid b-lazy b-loaded" alt="Baseball" />
              <h1 className='text-white text-center'>Dingerz</h1>
            </a>
          </div>
          <div className="col-md-4 gallery-item">
            <a href="images/Shout_out.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="images/Shout_out.png" className="img-fluid b-lazy b-loaded" alt="Shoot Out" />
              <h1 className='text-white text-center'>Shoot-out</h1>
            </a>
          </div>
          <div className="col-md-4 gallery-item">
            <a href="./images/Golf.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="./images/Golf.png" className="img-fluid b-lazy b-loaded" alt="Golf" />
              <h1 className='text-white text-center'>Links</h1>
            </a>
          </div>
          <div className="col-md-4 gallery-item">
            <a href="./images/Football.png" data-lightbox="studio_gallery">
              <div className="overlay"></div>
              <img src="./images/Football.png" className="img-fluid b-lazy b-loaded" alt="Football" />
              <h1 className='text-white text-center'>Token Toss</h1>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
