import React from "react";

export default function Discord() {
  return (
    <section id="cta-section">
      <div className="container py-5 my-5">
        <div className="row tiny-margin cta-card">
          <div className="col-md-6">
            <img
              src="./images/discord.png"
              className="img-fluid game-box animation-element slide-in-left"
              width="300"
              alt="discord"
            />
          </div>
          <div className="col-md-6">
            <h1>
              JOIN
              <br />
              OUR
              <br />
              DISCORD
            </h1>
            <a className="button text-nowrap" href="
https://discord.com/invite/TBKTP8cmUW">
              <i className="fab fa-discord"></i> JOIN NOW
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
