import React from 'react'

export default function Footer() {
  return (
    <div id="footer">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-12">
            <p id="copyright">
              &copy; MARTIAN UNIVERSE <span id="year"> </span> - All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
