import React from "react";

export default function Exchange() {
  return (
    <>
      {/* <h1 className="short-hr-center text-center my-5 py-5">Exchange</h1> */}
      <div className="row extra-features">
        <div className="col-md-6 text-center pt-md-4 order-last order-md-first">
          <img
            src="images/market.png"
            className="img-fluid animation-element slide-in-left p-0 m-0 mt-md-5 pt-md-5"
            width="700"
            alt="exchange"
          />
        </div>
        <div className="col-xl-6 col-md-12 order-first order-md-last">
          <h2 className="short-hr-left">Exchange</h2>
          <p className="text">
            Buy, sell, trade, rent your digital assets on the Martians
            marketplace. Extract utility from your collections through airdrops
            and redeemable merchandise, product partners and life experiences.
          </p>
        </div>
      </div>
    </>
  );
}
